import { AuthService } from 'src/app/core/auth/auth.service';
import { DashBoardContactsService } from './../../../../core/services/dashboard-contact.service';
import { ApplicationRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { User } from 'src/app/core/auth/user.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invitation-person-search',
  templateUrl: './invitation-person-search.component.html',
  styleUrls: ['./invitation-person-search.component.sass']
})
export class InvitationPersonSearchComponent implements OnInit, OnChanges{

  @ViewChild('keywordsInput') keywordsInput:ElementRef;

  @Input() eventUid:number;
  @Input() currentInvitations:any;
  @Input() show:boolean = false;

  @Output() state: EventEmitter<boolean> = new EventEmitter();
  @Output() newInvitesListener:EventEmitter<any> = new EventEmitter();

  current_user:User;

  constructor(private contactService:DashBoardContactsService, private authService: AuthService,private applicationRef:ApplicationRef ) {

    this.authService.user.subscribe(res => {
      this.current_user = res;
    });

  }

  showInvitations:boolean = true;
  newInvites:any = [];
  listItems:any = {page:1,items:[]};

  searchTimeout = null;

  ngOnInit(): void {


  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        let change = changes[propName];
        switch (propName) {
          case 'show': {
            if(change.previousValue != change.currentValue){
              if(change.currentValue == true){
                //There needs to be a timeout because it takes a bit before we can access the element
                setTimeout(() => {
                  this.keywordsInput.nativeElement.focus();
                }, 100)
              }
            }
          }
        }
      }
    }
  }


  goBack(){
    this.state.emit(false);
  }

  onKeyUp(){
    let keywords = this.keywordsInput.nativeElement.value;
    if(this.searchTimeout){
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.searchForContacts(keywords);
    }, 200);
  }

  searchForContacts(keywords:string){
    this.contactService.getContactList(keywords).subscribe(res => {
      if(res){
        let result_with_photo = this.addPhotoUrlToContacts(res);
        let result_done = this.addInvitationStateToContacts(result_with_photo);
        let results_sorted = this.sortResultsOnExactSearchMatches(this.keywordsInput.nativeElement.value, result_done);
        this.listItems = {page:1,items: results_sorted};
      }
    });
  }

  sortResultsOnExactSearchMatches(searchTerm, inputList:any){

    // Make sure that the items that have an exact name match with the search term are on top
    var inputlistE = inputList;
    var searchTerms = searchTerm.split(' ');
    var componentScope = this;

    inputlistE.forEach(function(contact){
      var exactMatch = 0;
      searchTerms.forEach(function(term){
        if(contact && contact.Person && contact.Person.firstName && contact.Person.lastName){
          if(contact.Person.firstName.toLowerCase() == term.toLowerCase() || contact.Person.lastName.toLowerCase() == term.toLowerCase()){
            exactMatch++;
          }
        }
      });
      contact.exactMatch = exactMatch;
    });

    inputlistE.sort(function(a, b){
      return b.exactMatch - a.exactMatch;
    });

    return inputlistE;

  }

  onContactClicked(contact:any){
    let searchForContactInInvitations = this.newInvites.findIndex(x => x.uid === contact.uid);
    if(searchForContactInInvitations === -1){
      if(contact.invitatationState == -2){
        this.newInvites.push({
          uid: contact.uid,
          statusId: -1,
          entityId: contact.entityId,
          firstName: contact.Person.firstName,
          infix: contact.Person.infix,
          lastName: contact.Person.lastName
        });
      }
    }else{
      this.newInvites.splice(searchForContactInInvitations, 1);
    }
    this.triggerChange();
    this.newInvitesListener.emit(this.newInvites);
  }

  onRemoveInvitation(contact){
    let searchForContactInInvitations = this.newInvites.findIndex(x => x.uid === contact.uid);
    this.newInvites.splice(searchForContactInInvitations, 1);
    this.triggerChange();
    this.newInvitesListener.emit(this.newInvites);
  }

  triggerChange(){
    // For some reason angular doesnt detect changes all the time
    this.showInvitations = false;
    this.applicationRef.tick();
    this.showInvitations = true;
  }

  addInvitationStateToContacts(inputList:any){
    var inputlistE = inputList;
    var componentScope = this;

    inputlistE.forEach(function(contact){

      // Relation contact
      if(contact.entityId == 4){
        var indexWithinCurrentInvites = componentScope.currentInvitations.findIndex(x => x.contactPersonUid === contact.uid);
      }

      // Candidate
      if(contact.entityId == 2){
        var indexWithinCurrentInvites = componentScope.currentInvitations.findIndex(x => x.candidateUid === contact.uid);
      }

      // Consultant
      if(contact.entityId == 14){
        var indexWithinCurrentInvites = componentScope.currentInvitations.findIndex(x => x.userId === contact.uid);
      }

      if(indexWithinCurrentInvites !== -1){
        contact.invitatationState = componentScope.currentInvitations[indexWithinCurrentInvites].statusId;
      }else{
        contact.invitatationState = -2;
      }
    });
    return inputlistE;
  }

  addPhotoUrlToContacts(inputList:any){
    var inputlistE = inputList;
    var sess = this.current_user.sessionId;
    inputlistE.forEach(function(contact){
      if(contact.Person.photoFileName){
        var imgClass = 'UserPhoto';
        if(contact.entity == 'Consultant'){
          imgClass = 'UserPhoto';
        }else if(contact.entity == 'Candidate'){
          imgClass = 'CandidatePhoto';
        }else if(contact.entity == 'RelationContact'){
          imgClass = 'RelationContactPhoto';
        }
        contact.logoUrl = environment.fileServiceDownload + '?ouid=' + contact.uid + '&class='+imgClass+'&sessionId=' + sess;
      }else{
        contact.logoUrl = null;
      }
    });
    return inputlistE;
  }

}
