import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { User } from '../auth/user.model';
import { map } from 'rxjs';

@Injectable()
export class DashBoardContactsService {
  user: User;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }

  // Check which modules this user has rights to
  getContactList(searchTerm: string) {
    // Define the OWS method
    const requestMethod = 'Otys.Services.MultiService.execute';
    // Define the body of the request (params)


    const userSearchArray = [];
    const searchTerms = searchTerm.split(' ');
    // Loop trough the search terms and add them to the search array
    if(searchTerms.length > 0){
      searchTerms.forEach((term) => {
        userSearchArray.push(
          {
            type: 'OR',
            invert: false,
            items: [
              {
                type: 'COND',
                field: 'Person.firstName',
                op: 'LIKE',
                param: '%'+term+'%',
              },
              {
                type: 'COND',
                field: 'Person.lastName',
                op: 'LIKE',
                param: '%'+term+'%',
              },
              {
                type: 'COND',
                field: 'Person.emailPrimary',
                op: 'LIKE',
                param: '%'+term+'%',
              },
              {
                type: 'COND',
                field: 'Person.infix',
                op: 'LIKE',
                param: '%'+term+'%',
              },
            ],
          }
        );
      });
    }








    const requestBody = [[
      {
        method: 'Otys.Services.CandidateService.getListEx',
        args: [
          this.user.sessionId,
          {
            search: {
              ACTONOMY: {
                DATA: {
                  keywords: {
                    options: {
                      enableExpansion: false,
                      required: true,
                      searchList: [
                        'firstName',
                        'lastName',
                      ],
                      searchMode: 'one',
                    },
                    value: searchTerm + '*',
                  },
                },
                VERSION: 2,
                SUB_VERSION: 0,
                OPTIONS: {
                  limit: 15,
                  offset: 0,
                  sort: {
                    SCORE: 'DESC',
                    entryDateFull: 'DESC',
                  },
                },
              },
            },
            what: {
              internalId: 1,
              Person: {
                uid: 1,
                emailPrimary: 1,
                firstName: 1,
                infix: 1,
                lastName: 1,
                photoFileName: 1,
              },
              uid: 1,
              status: 1,
              hasCv: 1,
            },
          },
        ],
      },
      {
        method: 'Otys.Services.RelationContactService.getListEx',
        args: [
          this.user.sessionId,
          {
            what: {
              uid: 1,
              Person: {
                firstName: 1,
                infix: 1,
                lastName: 1,
              },
			        relation: 1,
              relationId: 1,
            },
            limit: 15,
            offset: 0,
            getTotalCount: true,
            sort: {
              entryDateTime: 'DESC',
            },
            condition: {
              type: 'AND',
              items: [
                {
                  type: 'OR',
                  items: [
                    {
                      type: 'COND',
                      field: 'Person.firstName',
                      op: 'LIKE',
                      param: '%' + searchTerm + '%',
                    },
                    {
                      type: 'COND',
                      field: 'Person.infix',
                      op: 'LIKE',
                      param: '%' + searchTerm + '%',
                    },
                    {
                      type: 'COND',
                      field: 'Person.lastName',
                      op: 'LIKE',
                      param: '%' + searchTerm + '%',
                    },
                  ],
                },
                {
                  type: 'AND',
                  items: [
                    {
                      type: 'COND',
                      field: 'isPassive',
                      op: 'EQ',
                      param: false,
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      {
        method: 'Otys.Services.UserService.getList',
        args: [
          this.user.sessionId,
          {
            what: {
              Person: {
                firstName: 1,
                infix: 1,
                lastName: 1,
                emailPrimary: 1,
				        photoFileName: 1,
              },
              uid: 1,
            },
            limit: 1000,
            condition: {
              type: 'AND',
              invert: false,
              items: [
                {
                  type: 'COND',
                  field: 'blocked',
                  op: 'EQ',
                  param: false,
                },
                {
                  type: 'COND',
                  field: 'uid',
                  op: 'NE',
                  param: [],
                },
                {
                  type: 'AND',
                  invert: false,
                  items: userSearchArray,
                },
              ],
            },
          },
        ],
      },
    ]];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false',
      },
    };

    // Retrieve live data
	// And modify the response so that all 3 arrays are combined in one array with persons
	return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).pipe(	
		map((response) => {
			return response.result.map((data, index) => {
				// The users array is the only one that has a different structure
				if(data.data && data.data.listOutput){

					return data.data.listOutput.map((person:any) => {
						if (person.Person) {

							let entityName = 'Candidate';
							let entityId = 2;
							if(index == 1){
								entityName = 'RelationContact';
								entityId = 4;
							}

							return {
								uid: person.uid,
								Person: {
									uid: person.Person.uid,
									emailPrimary: person.Person.emailPrimary,
									firstName: person.Person.firstName,
									infix: person.Person.infix,
									lastName: person.Person.lastName,
									photoFileName: person.Person.photoFileName
								},
								entity: entityName,
								entityId: entityId,
								relation: person.relation ?? '',
								photoFileName: person.Person.photoFileName
							};
						}else{
							return {};
						}
					});

				}else{

          if(data && data.data){
            return data.data.map((person) => {
              if (person.Person) {
                return {
                  uid: person.uid,
                  Person: {
                    uid: person.Person.uid,
                    emailPrimary: person.Person.emailPrimary,
                    firstName: person.Person.firstName,
                    infix: person.Person.infix,
                    lastName: person.Person.lastName,
                    photoFileName: person.Person.photoFileName
                  },
                  entity: "Consultant",
                  entityId: 14,
                };
              }else{
                return {};
              }
            });
          }else{
            return [];
          }

					
				}

			}).flat();
		})
	);
  }
}
